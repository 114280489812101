<template>
  <div>
    <p class="centered"><img src="../../../assets/img/island-candos-logo.png" alt="Island Condos" height="85"></p>

    <h1 class="title">RESIDENT HANDBOOK</h1>

    <p><b>Welcome</b></p>

    <p>Thank you for choosing Island Condos, we’re delighted to have you! We hope that you enjoy your stay with us and,
      to help you get acclimated to our environment, we have compiled some helpful information for you. Please take some
      time to read over our policies, regulations, and services.</p>

    <p>Island Condos Student Housing is committed to maintaining the health and safety of each student on our premises
      and strives to foster an atmosphere of positive engagement and mutual respect. To that end, every resident is
      expected to review this handbook in its entirety and abide by the rules and regulations herein. Of course, it is
      not possible to apply an official policy to every situation that could occur; in instances where there is no
      written policy, it is important for each member of the community to think critically about their behavior and its
      potential impact on others in our shared living spaces. Policies may be updated without notice.</p>

    <p>Additionally, your occupancy is predicated on the understanding that you are renting a space in student housing,
      not any specific room and/or building.</p>

    <p>Housing management is available to answer any questions or address any issues that you may have. Please contact
      us at <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a></p>

    <h2>Room Types and Fees</h2>

    <table>
      <tr>
        <th>Room Type</th>
        <th>Type of Bed</th>
        <th>Monthly Fee</th>
      </tr>
      <tr class="highlight">
        <td>Double room with private balcony</td>
        <td>Day bed</td>
        <td>$1050</td>
      </tr>
      <tr>
        <td>Double room</td>
        <td>Day bed</td>
        <td>$920</td>
      </tr>
      <tr class="highlight">
        <td>Double room</td>
        <td>Bunk bed</td>
        <td>$820</td>
      </tr>
      <tr>
        <td>Triple room</td>
        <td>Day bed</td>
        <td>$600</td>
      </tr>
      <tr class="highlight">
        <td>Quadruple room</td>
        <td>Bunk bed</td>
        <td>$500</td>
      </tr>
      <tr>
        <td>Double Room (Building 632)*</td>
        <td>Day bed</td>
        <td>$1050</td>
      </tr>
      <tr class="highlight">
        <td>Double Room (Building 632)*</td>
        <td>Bunk bed</td>
        <td>$920</td>
      </tr>
    </table>

    <p>*Building 632 has larger common areas shared by fewer residents.</p>

    <p>Common areas including kitchens, bathrooms, and lounges, are shared with other residents. The exact number of
      occupants will depend on your room type.</p>

    <h2>Payments</h2>

    <p>Payments are due on the 1<sup>st</sup> day of every month. Tenants may submit payment through Zelle, or <a
        href="https://ois.bau.edu/fi/epayment/housing/ln/en">the online payment portal</a>. Cash and payments by check
      are also accepted in the Finance Office located on the 9<sup>th</sup> floor of the Bay Atlantic University campus.
      If your account is not resolved by the 5<sup>th</sup> day of the month, you will be charged a $50 late fee and
      that fee will increase by $50 every 5 days.</p>

    <p>Example:</p>
    <ul>
      <li>September 1-5: $50 late fee</li>
      <li>September 6-10: $100 late fee</li>
    </ul>

    <p>If you have not resolved your account by the 15<sup>th</sup> of the month, you will be asked to vacate the
      property.</p>

    <h2>Room Assignments</h2>

    <p>Student Housing reserves all rights concerning any room assignment, reassignment, adjustment, and/or
      consolidation of student spaces. Student Housing further reserves the right to assign tenants to temporary space,
      when necessary, and to reassign the tenant to permanent space when it becomes available. If a vacancy occurs in
      the room to which you are assigned, you must maintain the open space so that it is ready for a new occupant at any
      time.</p>

    <h2>Failure to Maintain Open Space</h2>

    <p>If it is discovered that you have not maintained the temporarily open space in your room, your security deposit
      will be forfeited. Additionally, you may be charged the full cost of the open space if a new occupant is unable to
      move in as a consequence.</p>

    <h2>Room Changes</h2>

    <p>Students may request room changes for the following reasons:</p>

    <ol>
      <li><b>Genuine safety concern:</b> A resident in need of a room change due to safety concerns should contact the
        Director of Student Services at <a href="mailto:rmckie@bau.edu">rmckie@bau.edu</a> immediately.
      </li>
      <li><b>Medical need:</b> Residents who have a medical need in which a room change is necessary, please contact the
        Director of Student Services directly at <a href="mailto:rmckie@bau.edu">rmckie@bau.edu</a>.
      </li>
      <li><b>Roommate conflict:</b> There may be times when a student’s living situation becomes challenging due to
        issues with their roommates or community. Students experiencing a roommate conflict may be asked to work with
        student housing staff to mediate the dispute before a room change is approved.
      </li>
      <li><b>Desire to live in a different room type:</b> Some students may wish to move to a more spacious or cozier
        room.
      </li>
    </ol>

    <p><a href="https://docs.google.com/document/d/1OuWSKGPQzz3ZAG7q0hdM92ElE-OqTsTrXneMR7V__sU/edit"><b>Room Change
      Request Form</b></a></p>

    <p><b>Please note:</b> Island Condos is committed to diversity and inclusion, and room change requests based on
      national origin, race, religion, sexual orientation, age, or any other discriminatory reason <b><u>WILL NOT be
        entertained.</u></b></p>

    <h2>Right of Entry</h2>

    <p>Student Housing reserves the right to enter any room, at any time, with or without prior notification, for
      reasons including but not limited to:</p>

    <ol>
      <li>Health, safety, sanitation, maintenance, and inspection or to show vacancies.</li>
      <li>To stop noises from speakers, mobile devices, alarm clocks, etc., that are presenting a disruption to the
        community when occupants are not available to take action themselves.
      </li>
      <li>To make alterations, improvements, or repairs.</li>
      <li>In case of emergency (e.g., situations that would result in harm to persons or damage to property).</li>
      <li>General inspections.</li>
      <li>Suspected abandonment of room.</li>
      <li>Pursuant to court order.</li>
    </ol>

    <p>If an unscheduled entry is necessary (except in cases of emergency), two authorized members of staff will be
      present at all times and will leave you notice of:</p>

    <ol>
      <li>Date and time of entry.</li>
      <li>Reason for entry.</li>
      <li>Action taken (if applicable).</li>
    </ol>

    <h2>Items Provided by Island Condos</h2>

    <p><i>Personal Rooms</i></p>

    <p>All rooms are fully furnished with beds and either mobile or built-in storage. Additionally, your room will be
      supplied with:</p>

    <ul>
      <li>Linens – fitted and top sheet, 1 pillow and pillowcase, and 1 blanket (per resident)</li>
      <li>Full-size mirror</li>
      <li>Dressers</li>
    </ul>

    <p><i>Common Areas</i></p>

    <ul>
      <li>Kitchenware</li>
      <li>All major kitchen appliances including refrigerator, stove/oven, and microwave</li>
      <li>Washer/dryer</li>
    </ul>

    <p>In the event of damage to or loss of any items provided by Island Condos, the cost of the item’s replacement will
      be deducted from the security deposit of the unit residents. Should the cost of replacement exceed the amount of
      the security deposit, the remaining debt will be billed.</p>

    <h2>Things Not Supplied by Island Condos</h2>

    <p>Personal hygiene products such as soap, shampoo, conditioner, toothpaste, toothbrushes, towels, etc., are the
      responsibility of the tenant. Additionally, common area cleaning products like laundry detergent, dishwasher and
      dishwashing liquid, soap, paper towels, and garbage bags <b>are not provided.</b></p>

    <h2>Keys and Fobs</h2>

    <p>Each resident will be given a room key and a fob to open the outside gate. It is the responsibility of every
      resident to keep their keys safe and not share them with outside parties. Both your key and your fob must be
      returned at check-out. If you do not return either or both, you will be charged for lost keys and fobs.</p>

    <h2>Fees for Lost Keys and Fobs</h2>

    <p>If you misplace your key and/or fob, please inform student housing staff, and they will assist you. You will be
      asked to show a government-issued ID (passport or US driver license) to gain access to your room. Unfortunately,
      we cannot grant access to anyone who cannot prove their identity. Please take a photo of the picture page of your
      passport and/or your license and always keep it in your gallery.</p>

    <ul>
      <li>Lost key: $75</li>
      <li>Lost fob: $75</li>
      <li>Lost key and fob: $150</li>
    </ul>

    <h2>Luggage Storage</h2>

    <p>Tenants are advised to use the luggage storage which is available on the rooftop of most units. Storing large
      items such as luggage in rooms is a violation of DC Fire Code and is, consequently, strictly prohibited.</p>

    <p>If rooftop storage is not available, you will be directed to a storage location in your building.</p>

    <h2>Mail</h2>

    <p>Mail will be received and is available for pick up at the Island Condo security office between 10:00 AM and 8:00
      PM. Mail and parcels will be held for 30 days, after which, it will be marked unclaimed and returned to the
      sender. Be sure to check the mailboxes located in front of the security office regularly.</p>

    <p>Please note: Island Condos <b><u>is not</u></b> responsible for lost, stolen, or undelivered mail.</p>

    <h2>Internet Access</h2>

    <p>Each unit has its own network, so please find yours in the network list on your device. The password for all
      units is Isl@nd2016.</p>

    <h2>Pets</h2>

    <p>All pets are strictly prohibited in our buildings except for documented service animals.</p>

    <h2>Service Animals</h2>

    <p>A service animal is defined in Title II Section 35.104 under the Americans with Disabilities Act (ADA) as a dog
      that is trained to do work or perform tasks for the benefit of an individual with a disability including a
      physical, sensory, psychiatric, intellectual, or other mental disability.</p>

    <p>Examples of such work or tasks include but are not limited to:</p>

    <ul>
      <li>Guiding people who are blind or have low vision.</li>
      <li>Alerting deaf persons to the presence of people or sounds.</li>
      <li>Wheelchair assistance.</li>
      <li>Alerting an individual of a seizure, change in blood sugar, or allergen.</li>
      <li>Reminding a person with mental illness to take prescribed medications.</li>
      <li>Calming a person with PTSD during an anxiety attack.</li>
    </ul>

    <p>If you will bring your service animal to Island Condos, please complete the <a
        href="https://docs.google.com/document/d/1-G19ALsGUQe0MaU3tPQG42ccwZ_BnX2cCmr0gD9SkxA/edit?usp=sharing"><b>Service
      Animal Housing Request Form</b></a></p>

    <h2>Bed Holds</h2>

    <p>If you will travel outside of Washington, DC but plan to return to Island Condos and would like to stay in the
      same room, you may request that we hold your room. <b>Please note:</b> a request is <b><u>not</u></b> a guarantee,
      and approval is contingent on availability.</p>

    <p><a href="https://docs.google.com/document/d/1Nq4r7DZj22dg-_5eZ8xyiP-aR0JMKcwuzUfPqWyTtXw/edit"><b>Bed Hold
      Request Form</b></a></p>

    <h2>Bed Hold Fees</h2>

    <p>September 1<sup>st</sup> – May 30<sup>th</sup>: Full monthly fee</p>
    <p>June 1<sup>st</sup> – August 31<sup>st</sup>: Half of the monthly fee</p>

    <p>Example:</p>

    <p>If you are staying in a room that costs $1050 per month and you are traveling in December, you will need to pay
      the full $1050 to hold your room.</p>

    <p>If you are staying in a room that costs $1050 per month and you are traveling in July, you will need to pay $525
      to hold your room.</p>

    <h2 class="subtitle">Expected Behavior of Island Condo Tenants</h2>

    <p><em>Housing firmly believes that residents shall not engage in actions that impede a roommate’s ability to occupy
      a space or room. Further, residents shall not infringe on the rights of other community members or avert others
      from experiencing a safe, respectful, and hygienic environment.</em></p>

    <h2>Compliance with Student Housing Staff</h2>

    <p>Student Housing’s primary means of communication with current residents is through email. Failure to monitor the
      email address provided will not exempt residents from adhering to information and deadlines communicated.</p>

    <p>All residents are expected to cooperate with staff and adhere to reasonable requests. If you have a concern about
      the validity of a request, residents are expected to comply in the moment and to contact the Director of Student
      Services as soon as possible.</p>

    <p>Verbal abuse, physical intimidation, or menacing behaviors directed towards staff members, the display of
      materials that demean staff, and/or interfere with staff members engaged in the performance of assigned
      responsibilities are prohibited.</p>

    <p>The use of physical force of any kind against staff is strictly prohibited and will result in:</p>

    <ol>
      <li>Forfeiture of security deposit.</li>
      <li>Report to law enforcement.</li>
      <li>Eviction from Island Condos.</li>
      <li>If your stay is prepaid, no funds will be returned.</li>
      <li>If your stay is monthly, your removal does not exempt you from the housing contract, and you will be expected
        to remit payment for the remainder of your contract with Island Condos.
      </li>
    </ol>

    <h2>Guest Policy</h2>

    <p>Outside guests are not permitted to enter either the private rooms or the common areas of Island Condos. If you
      are found to be entertaining guests who are not also residents of Island Condos:</p>

    <p><u>First infraction:</u> Written warning</p>

    <p><u>Second infraction:</u> Eviction and forfeiture of security deposit.</p>

    <p>Island Condo residents may visit with friends from other units in common areas <b><u>ONLY</u></b> until
      designated quiet hours.</p>

    <h2>Noise Policy</h2>

    <p>Designated quiet hours are 10:00 PM – 7:00 AM, Sunday – Thursday, and 12:00 AM (midnight) – 7:00 AM, Friday and
      Saturday.</p>

    <p>Students are encouraged to settle minor noise issues amongst themselves before approaching staff; however, if you
      do not feel comfortable for any reason or the situation seems to be escalating, please contact the security office
      for assistance.</p>

    <h2>Activities During Quiet Hours</h2>

    <p>Activities and events which may produce higher than average noise levels may be scheduled during designated quiet
      hours with expressed approval from Student Housing Management and advanced notice to the community. If you would
      like to plan an event, please send your request to <a
          href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.</p>

    <p>Please allow up to 48 business hours for your request to be processed.</p>

    <p>Please note, you are responsible for your guests and will be held liable for any damages or complaints.</p>

    <h2>Cleaning Policy</h2>

    <p>Tenants are responsible for maintaining a hygienic environment in their personal rooms, bathrooms, and common
      spaces. If unreasonable levels of clutter are observed during routine inspections, the tenant will be given a
      written warning and a deadline for rectifying the situation. If the situation continues unaddressed, the security
      deposit will be forfeited and, in extreme cases, the tenant may be removed from student housing.</p>

    <p>You are expected to keep all common areas clean.</p>

    <ul>
      <li>Do not leave dirty dishes in the sink or on counters.</li>
      <li>Empty the dishwasher.</li>
      <li>Take out the trash.</li>
      <li>Clean out tubs and wipe down surfaces in bathrooms.</li>
    </ul>

    <p>Unattended food, dirty dishes, and trash attract pests. Student Housing provides regular pest control
      maintenance; however, if it is found that additional services are required as a result of unhygienic conditions,
      the cost of those services will be deducted from the security deposits of the residents of the unit in question
      and/or being treated.</p>

    <p>If there are multiple units in violation of the cleaning policy, the costs of additional pest control services
      will be divided evenly among the residents of the building.</p>

    <h2>Cleaning Service</h2>

    <p>A cleaning service has been contracted to clean common areas bi-monthly. Their sole responsibilities are the
      oven, the microwave, kitchen countertops, and kitchen and bathroom floors.</p>

    <p>If your bathroom, kitchen, or the common areas of your building are unreasonably cluttered, you will receive a
      written warning, and cleaning services will not be rendered until the space(s) are tidied.</p>

    <p>Failure to perform necessary cleaning after your written warning will result in probation.</p>

    <h2>Maintenance Repairs</h2>

    <p>Maintenance emergencies are defined as situations that, if left unresolved, could result in injury, threaten
      one’s health, or cause serious property damage in the very short term. If you are experiencing:</p>

    <ul>
      <li>Fire</li>
      <li>Flooding</li>
      <li>Gas leak</li>
      <li>Carbon monoxide</li>
      <li>No electricity</li>
      <li>No water</li>
      <li>No heat when temperatures are below 55 degrees F</li>
    </ul>

    <p>This constitutes a ‘maintenance emergency’ and you should report immediately to the security office.</p>

    <p>If you are experiencing a maintenance issue that is not an emergency, please send an email to <a
        href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>. It is your responsibility to report maintenance
      issues as quickly as possible.</p>

    <h2>Damage and Vandalism</h2>

    <p>Residents may not deliberately or through negligence deface or destroy Island Condos property or property
      belonging to another resident. You are expected to reimburse Student Housing for any damage that you cause. The
      cost of replacement or repair will be deducted from your security deposit. If the amount exceeds the security
      deposit, the additional costs will be billed.</p>

    <h2>Communal Damages</h2>

    <p>In the event of property damage, the cost of repair or replacement will be deducted from the security deposit of
      the responsible party. If the cost exceeds the security deposit, the additional cost will be billed to the
      tenant.</p>

    <p>In cases where it is not possible to determine who is responsible for damage, the entire unit or building will be
      charged; and the price of replacement or repair will be deducted equally from the security deposits of all
      tenants. If the cost exceeds the security deposits, the additional cost will be shared equally.</p>

    <p>If your security deposit is used to cover damages while you are still living in student housing, you will be
      required to pay an additional security deposit to offset the cost of any additional damages that may be incurred.
      If there are no further damages or violations, the deposit will be returned upon move out as usual.</p>

    <h2>Check Out</h2>

    <p>Residents are expected to notify Student Housing of their intentions to vacate their room <b>14 business days</b>
      prior to moving out <b><i><u>even if it is the end of your contract.</u></i></b> <b><i><u>We need this time to
        prepare your security deposit so, if you do not give notice of move out, return of your deposit will be delayed
        and you will not receive it that day.</u></i></b></p>

    <p>Rooms should be left in the same condition they were received: free of personal items, trash, and the
      refrigerator must be emptied of your food items. Linens should be left on the bed and all windows should be closed
      and locked.</p>

    <p>Your room will be inspected by a member of staff before your security deposit is returned. Any damage found will
      be deducted from your security deposit. If the damage exceeds the security deposit, you will be billed for the
      additional cost.</p>

    <p>Keys and fobs MUST be returned to student housing upon check out. If they are not returned, you will be charged
      $75 for each missing item (i.e., missing key $75, missing key and fob $150).</p>

    <h2><b><i><u>Health and Safety</u></i></b></h2>

    <p>The security office is located to the left of the main entrance of Island Condos and is staffed 24 hours a day.
      After 5 pm, the management staff is replaced with security guards.</p>

    <p>Locking rooms and securing belongings are the sole responsibility of the tenant. Island Condos accepts no
      responsibility for lost or stolen property.</p>

    <h2>Entry and Exit</h2>

    <p>All entry and exit should be through the Island Condos main gate next to the security office. All doors facing
      Girard Street must remain locked at all times and entry/exit is strictly prohibited. Using the front doors without
      written permission from Student Housing will result in:</p>

    <p>First infraction: written warning.</p>
    <p>Second infraction: forfeiture of security deposit and probation</p>
    <p>Third infraction: eviction.</p>

    <h2>Alcohol</h2>

    <p>The possession, use or intent to distribute alcoholic beverages and/or dangerous substances of any kind (even
      legal ones like marijuana and mushrooms) is strictly prohibited even if you are of legal age (21 years old) to
      consume them. If you are found with alcohol and/or controlled substances for personal use:</p>

    <p><u>International Students</u> will forfeit their security deposit, be permanently banned from Island Condos, and
      have their I-20’s terminated. If you have prepaid, your contract with Island Condos will continue through the
      original housing dates and your money will not be returned.</p>

    <p><u>Domestic Students</u> will forfeit their security deposit and be permanently banned from Island Condos. Your
      contract with Island Condos will continue through the original housing dates and any pre-payments will not be
      returned.</p>

    <h2>Controlled Substances</h2>

    <p>If distribution of controlled substances is suspected, law enforcement may be called, and criminal charges may be
      filed.</p>

    <p><i>If you are found with drug-related paraphernalia:</i></p>

    <p>First infraction: written warning</p>
    <p>Second infraction: forfeiture of security deposit and removal from Student Housing.</p>

    <p>Please note: the misuse of prescription or over-the-counter medication, including, but not limited to, sales and
      distribution is prohibited. If a student is suspected of distributing prescription medication, law enforcement may
      be notified.</p>

    <h2>Tobacco Use</h2>

    <p>Please note that the legal smoking age in Washington, DC is 21 years old. Smoking cigarettes and/or vapes in
      personal rooms or common areas of student housing is strictly prohibited.</p>

    <p>You may smoke outside but you must be at least 20 feet away from the building. Cigarette butts must be disposed
      of properly.</p>

    <p>If you are found smoking within 20 feet of the building or not disposing of cigarettes properly:</p>

    <p>First infraction: verbal warning</p>
    <p>Second infraction: written warning and probation</p>
    <p>Third infraction: forfeiture of security deposit and removal from Island Condos</p>

    <h2>Disorderly Conduct</h2>

    <p>Disorderly conduct is conduct that is boisterous, unruly or is otherwise disruptive to the living community.
      Examples include but are not limited to:</p>

    <ol class="numbered-list">
      <li>Posting/writing harassing materials.</li>
      <li>Pranks which include theft or damage to property, violate other policies, or put others in danger are strictly
        prohibited and taken very seriously.
      </li>
      <li>Depositing body fluids, including but not limited to: vomiting, urinating, or defecating in public areas or
        inappropriate locations is prohibited.
      </li>
      <li>Engaging in assaultive behavior.</li>
      <li>Making threats of harm directed at others.</li>
      <li>Harassment directed at other residents, staff, students, or guests.</li>
    </ol>

    <p>Penalties for disorderly conduct are dependent on the nature of the violation and can range from a written
      warning to immediate expulsion from student housing. Any infraction that is a violation of Washington, DC law will
      be reported to local law enforcement.</p>

    <h2>Weapons</h2>

    <p>The possession and/or use of firearms and other dangerous weapons is prohibited. This includes but is not limited
      to firearms, replicas of firearms, paintball guns, pellet guns, BB guns, Airsoft BB guns, bows and arrows, ninja
      stars, knives and swords.</p>

    <p>The possession and/or use of dangerous weapon parts and/or accessories is prohibited. This includes, but is not
      limited to parts of guns, ammunition shells, shell casings, and plastic or metal pellets. Additionally, you may
      not display or portray as real any object that resembles a dangerous weapon.</p>

    <p>If you are found with a weapon, part of a weapon or weapon accessories:</p>

    <ol class="numbered-list">
      <li>Your security deposit will be forfeited.</li>
      <li>You will be immediately and permanently banned from Island Condos.</li>
      <li>If a weapon has been obtained illegally or the make of the weapon is prohibited by law, law enforcement will
        be notified.
      </li>
    </ol>

    <h2>Fire Safety</h2>

    <p>Residents must adhere to the Washington, DC <a
        href="https://code.dccouncil.gov/us/dc/council/code/titles/6/chapters/7/">fire code</a>. It is the
      responsibility of the tenant to acquaint themselves with the code in its entirety, but some common violations are:
    </p>

    <ol class="numbered-list">
      <li>Residents are prohibited from burning/lighting candles or incense in rooms and/or common areas.</li>
      <li>Wax heating electronics are prohibited.</li>
      <li>Residents are prohibited from having firecrackers/fireworks within, out of, or adjacent to residential
        communities.
      </li>
      <li>Setting fires in and around the residence halls is prohibited.</li>
      <li>Covering, disconnecting, or hanging items from smoke detectors is prohibited.</li>
      <li>Residents are not permitted to keep non-potted vegetation (i.e., Christmas trees, wreaths, straw, wood, and/or
        bark) in their rooms.
      </li>
      <li>Only circuit breaker extension cords are permitted.</li>
      <li>Residents may not cover any lighting fixture with any material including cloth, paper, etc.</li>
      <li>Items should not be affixed to or hung from room ceilings and/or pipes. These items include but are not
        limited to ceiling fans, clotheslines, flags, posters, string lights, etc.
      </li>
      <li>Gasoline, propane, lighter fluid, nitrous oxide, liquid nitrogen, turpentine, dry cleaning fluid, and any
        other highly flammable chemicals are prohibited.
      </li>
      <li>Rooms must have a clear path from the door to the other side of the room.</li>
    </ol>

    <p class="indent">If candles, incense or open flame are a necessary part of your firmly held religious beliefs,
      please complete the <a
          href="https://docs.google.com/document/d/1qZWlhTaY3SKR2hwdhX8eti4SJbKHbpfiO8_Az8MSPEQ/edit?usp=sharing">Religious
        Exemption Request Form</a>.</p>

    <p class="indent">Penalties for violating fire safety policies are dependent on the nature of the violation and can
      range from a verbal warning to immediate expulsion from student housing. Any infraction that is a violation of
      Washington, DC law will be reported to local law enforcement authorities.</p>

    <h3 class="indent">Tampering with Fire Safety Equipment</h3>

    <p class="indent">Residents are prohibited from tampering with fire safety equipment or refusing to evacuate during
      an alarm are serious violations of student housing policy and state law. Tampering with fire safety equipment may
      result in disciplinary action, exclusion from student housing, and even criminal charges. This includes but is not
      limited to:</p>

    <ul class="bullet-list">
      <li>Removing batteries from any alarm system.</li>
      <li>Disconnecting wiring of any alarm system.</li>
      <li>Muffling the sound of any alarm system.</li>
      <li>Removing hardware from fire doors to prevent proper latching/closing.</li>
      <li>Propping fire doors open.</li>
      <li>Blocking access to fire panels.</li>
      <li>Covering/removing smoke detectors.</li>
    </ul>

    <h2>General Instructions for All Emergency Situations:</h2>

    <p>1. Get out of immediate danger and stay calm.<br>
      2. In the event of a fire, or if you feel the building’s occupants are in danger:<br>
      &nbsp;&nbsp;&nbsp;a. Activate the building’s fire alarm system BEFORE calling 9-1-1.<br>
      &nbsp;&nbsp;&nbsp;b. Evacuate the building immediately!<br>
      &nbsp;&nbsp;&nbsp;c. Use the Building Evacuation Maps on each floor.</p>

    <h3><u>When Calling 9-1-1:</u></h3>

    <p>1. Stay on the line with the dispatcher.</p>
    <p>2. Provide the address of the building involved and your exact location. This is especially important if you are
      calling from a cell phone. Island Condos address is listed below:</p>
    <p>614 Girard Street, NE, Washington, D.C., 20017</p>
    <p>3. Provide a thorough description of the incident to ensure that proper resources are dispatched.</p>
    <p>4. When providing a description of an individual, describe from top (head) to bottom (feet).</p>
    <p>5. Do not hang up until the dispatcher tells you to.</p>

    <h3><u>Notification of Emergencies</u></h3>

    <p>Occupants will be notified of emergencies by either activating the installed fire alarm system, or occupants may
      receive verbal notification of an emergency.</p>

    <h3><u>Fire and Emergency Reporting</u></h3>

    <p>The preferred fire or emergency reporting method is by dialing 911 from any phone (even if the fire is out).
      Alternatively, activation of any fire alarm system device (smoke/heat detector, sprinkler head) will automatically
      summon emergency responders.</p>

    <h2>Evacuation Procedures</h2>

    <p>All residents must evacuate Island Condos during both fire drills and fire emergencies. Failure to do so is a
      violation of fire code and will result in:</p>

    <p>First infraction: written warning and automatic probation</p>
    <p>Second infraction: removal from Island Condos and forfeiture of security deposit</p>

    <p>Exit routes are posted in every building. Familiarize yourself with them so you are prepared in case of
      emergency.</p>

    <h2>Title IX</h2>

    <p>Island Condos believes that every student has the right to participate in community life without sex or gender
      discrimination; student housing policies on Title IX are in strict alignment with those of Bay Atlantic University
      and Mentora College.</p>

    <p>Bay Atlantic University’s policy states in part:</p>

    <p>According to Title IX of the Education Amendments of 1972: “No person in the United States shall, on the basis of
      sex, be excluded from participation in, be denied the benefits of, or be subjected to discrimination under any
      education program or activity…”</p>

    <p>Bay Atlantic University does not and will not tolerate sexual harassment of students, faculty, and/or staff.
      Sexual harassment is a form of sex discrimination that is illegal under Title IX of the Education Amendments of
      1972. Sexual harassment is deemed to have occurred when:</p>

    <ul>
      <li>Submission to harassment is either explicitly or implicitly a term or condition of an individual’s employment
        or academic performance; or
      </li>
      <li>Submission to or rejection of such conduct by an individual is used as the basis for academic or employment
        decisions, including, but not limited to promotion, transfer, selection for training or performance evaluation,
        or used as the basis for academic evaluation; or
      </li>
      <li>The conduct has the purpose or effect of unreasonably interfering with an affected person’s work performance
        or academic performance or participation in educational pursuits; or
      </li>
      <li>The conduct has the purpose or effect of creating an intimidating, hostile, or offensive environment.</li>
    </ul>

    <p>BAU offers training to students, faculty, and staff on Title IX at the beginning of each semester. The Title IX
      officer is Shawneen Jones, the HR Director, on the 2<sup>nd</sup> floor and at <a href="mailto:hr@bau.edu"><b>hr@bau.edu</b></a>.
    </p>

    <h3><b>Retaliation</b></h3>

    <p>According to Title IX of the Education Amendments, it is illegal to commit a retaliatory act (e.g., giving
      failing grades or preventing certain individuals from participation in programs because of an individual’s
      identity, because the student had filed a complaint).</p>

    <h3><b>Sexual Harassment Policy</b></h3>

    <p>Bay Atlantic University does not and will not tolerate sexual harassment of students, faculty, and/or staff.
      Sexual harassment is a form of sex discrimination that is illegal under Title IX of the Education Amendments of
      1972. Sexual harassment is deemed to have occurred when:</p>

    <ol class="numbered-list">
      <li>Submission to harassment is either explicitly or implicitly a term or condition of an individual’s employment
        or academic performance; or
      </li>
      <li>Submission to or rejection of such conduct by an individual is used as the basis for academic or employment
        decisions, including, but not limited to promotion, transfer, selection for training or performance evaluation,
        or used as the basis for academic evaluation; or
      </li>
      <li>The conduct has the purpose or effect of unreasonably interfering with an affected person’s work performance
        or academic performance or participation in educational pursuits; or
      </li>
      <li>The conduct has the purpose or effect of creating an intimidating, hostile, or offensive academic
        environment.
      </li>
    </ol>

    <p><a href="https://bau.edu/wp-content/uploads/2022/09/Title-IX-Policy.pdf"><b>Title IX Policy</b></a></p>
    <p><a href="https://bau.edu/wp-content/uploads/2022/09/title-ix-initial-complaint-form.pdf"><b>Title IX Initial
      Complaint Form</b></a></p>
    <p><a
        href="https://bau.edu/wp-content/uploads/2022/09/Anti-Discrimination-and-Harassment-Policy-Related-Title-IX-.pdf"><b>Anti
      Discrimination and Harassment Policy</b></a></p>

    <h2>Sexual Harassment/Violence</h2>

    <p>Sexual harassment, sexual violence, domestic violence and/or stalking are strictly prohibited. Occupants are
      responsible for adherence to the Bay Atlantic University and Mentora College policies on these matters.</p>

    <p>Each incident will be reviewed individually and penalties for infractions will be determined on a case-by-case
      basis ranging from a written warning to immediate expulsion from student housing.</p>

    <h2>Bias Incidents</h2>

    <p>Bias incidents are non-criminal activity committed against a person or property that is motivated, in whole or in
      part, by the offender’s bias against a victim’s real or perceived expression of race, ethnicity, national origin,
      sex, gender identity or expression, sexual orientation, disability (mental or physical), age, political
      affiliation, religion or any other physical or cultural characteristics. These actions can be physical, verbal, or
      electronic messages.</p>

    <p>Diversity, equity, and inclusion are important cornerstones of Bay Atlantic University and Mentora College and
      allegations of bias incidents are taken very seriously and are not tolerated under any circumstances. If you
      believe that you are a victim of or witness to a bias incident, please complete the <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdWPclBT8SPhuxma5edcUaz5CEWcTjJfllo9dSt30vf3yr9ZA/viewform?usp=sf_link">Bias
        Incident Report Form</a> and forward it to <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.
    </p>

    <p>Reports will be reviewed by a committee and if it is determined that you are guilty:</p>

    <ol class="numbered-list">
      <li>Your security deposit will be forfeited.</li>
      <li>You will be permanently banned from Island Condos.</li>
    </ol>

    <h3><b>Why You Should Report Bias or Hate</b></h3>

    <p>The purpose of this reporting system is to provide support and resources to involved parties but, also, to
      educate the community about behaviors that are deemed unacceptable in their new/temporary country. We want to make
      it clear that intolerance has no place at this institution and all reports will be taken seriously.</p>

    <p>If you are a witness to or a victim of bias or hate, please complete the incident report. Your name will NEVER be
      revealed but, if you would like to make this report anonymously, you can do so by typing:</p>

    <p>Name: anonymous</p>
    <p>Email: <a href="mailto:anonymous@bau.edu">anonymous@bau.edu</a></p>

    <p>In the form because they are required categories.</p>

    <h2>Hate Crimes</h2>

    <p>A hate crime occurs when a perpetrator engages in criminal behavior or acts against an individual, property,
      organization, or group based on the offender’s perceptions of the victims’ race, ethnicity, national origin, sex,
      gender identity or expression, sexual orientation, physical disability, or religion.</p>

    <p>Types of crimes may include but are not limited to vandalism, graffiti, threatening phone calls, online
      harassment, physical harassment, arson, battery, or physical attacks.</p>

    <p>If you are a victim of or a witness to a hate crime, you are encouraged to report it to security immediately.</p>

    <p>If you are accused of a hate crime, law enforcement authorities will be notified. If you are found guilty of a
      hate crime:</p>

    <ol class="numbered-list">
      <li>You will be immediately and permanently removed from the student housing.</li>
      <li>Your security deposit will be forfeited.</li>
      <li>This will not represent the end of your housing contract and you will be expected to remit payment for the
        remainder of your contract or, if you have prepaid, your housing fees will not be returned.
      </li>
    </ol>

    <h2>Airport Drop Off</h2>

    <p>It is possible to arrange airport transit through Island Condos. If you require transport to the airport, your
      request must be submitted in writing at least 7 days prior to departure. The fee is $150 and you should request
      this service by sending an email to <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a> with the
      following information:</p>

    <ol class="numbered-list">
      <li>Your full name</li>
      <li>Date of travel</li>
      <li>Time of travel</li>
      <li>Airline</li>
    </ol>

    <h2><b>Criminal Conviction or Protective Order</b></h2>

    <p>If you are arrested and/or convicted of any felony or any crime requiring your registration on the Sex Offender
      or Crimes Against Minors Registry (SOR), or if you become subject to a protective order at any time during your
      residency at Island Condos, you are required within seventy-two (72) hours of the arrest and/or conviction or
      entry of the protective order, to notify Island Condos in writing at <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.
    </p>

    <p>Island Condos reserves the right to remove any resident from the premises if, in its sole judgment, you may pose
      a threat of substantial harm to other residents or to property or if it is determined that you were offered
      residency under false pretenses.</p>

    <h2><b>Failure to Vacate</b></h2>

    <p>If a student fails to vacate at the agreed upon end of their residency, for any reason, Island Condos may
      exercise any or all of the following options: (a) remove the student and student's possessions from the premises;
      (b) change the door locks and bill the student; (c) bill the student for all damages suffered by student housing
      as a result of failure to vacate; for example, payment of attorney fees and expenses. In-house counsel fees will
      be assessed at the prevailing hourly rates charged by outside counsel.</p>

    <p>After 14 days, if you have still failed to vacate or make arrangements with Island Condos expressed, written
      approval, your property will be considered abandoned.</p>

    <h2><b>Subletting and Overnight Guests</b></h2>

    <p>You may not sublet the premises or any part thereof. Your housing unit should be solely used as your primary
      residence. Overnight stays are not permitted, and guest visits should be aligned with Island Condos guest policy
      previously outlined.</p>

    <h2><b>Removal of Student Property</b></h2>

    <p>In the event of an emergency or other exigent circumstance, Island Condos and its agents may remove students’
      belongings from the premises for cleaning, inspection, repair, storage, and/or protection. Island Condos does not
      assume any obligation or liability for loss or damage to items of personal property that may occur during removal
      of student belongings. Student housing may destroy and discard personal belongings such as perishable items,
      alcohol, illegal drugs and drug-related paraphernalia, explosives, and hazardous materials. Should Island Condos
      discover items that raise concern for immediate safety or a significant violation of housing policy or federal
      law, this matter may be referred to the appropriate authorities.</p>

    <h2><b>Abandoned Property</b></h2>

    <p>Any belongings left anywhere in the residence (including common areas) after the residency agreement ends or is
      cancelled are classified as abandoned property. Unsanctioned abandoned property will be held for <b><u>14</u></b>
      days. After 14 days, items may be donated to charitable organizations, sold or disposed of.</p>

    <h2><b>Commercial Enterprise</b></h2>

    <p>You may not conduct any activity for business or financial gain from a residential facility or any University
      property. Additionally, sales, solicitation, and peddling are strictly prohibited.</p>

    <h2><b>Insurance</b></h2>

    <p>Island Condos does not assume any obligation or liability for loss or damage to items of personal property which
      may occur in its buildings or on its grounds, before, during or after the terms of residency. This includes but is
      not limited to damage, loss, fire, water damage, theft, flooding, etc. Students are urged to purchase renter's
      insurance for protection against loss or property damage. We encourage you to research options on your own but, if
      you would like guidance on this issue, please contact us at <a href="mailto:studenthousing@bau.edu">studenthousing@bau.edu</a>.
    </p>

    <h2>Resource Guide</h2>

    <p>Linked below is a comprehensive guide for medical, housing, crisis, etc., resources in DC, MD, and VA.</p>

    <p><a href="https://example.com/ComprehensiveResourceGuideForDMV.pdf">Resource Guide</a></p>

    <h2><b>Additional Provisions</b></h2>

    <p>Residents are subject to all relevant Island Condos policies, which may be amended or updated from time to time
      in the sole discretion of Island Condos. Student housing retains the unilateral right to terminate the residential
      agreement at any time. Failure of either party to insist upon strict performance of any of the Terms or Conditions
      herein shall not be deemed a waiver of any rights or remedies of either party and shall not be deemed a waiver of
      any subsequent breach or default in any of the Terms or Conditions herein. Island Condos assumes no responsibility
      for failure to perform any terms or conditions of this agreement due to any force majeure. For purposes of this
      agreement, the term "force majeure" shall mean fire, earthquake, flood, hurricane, or other significant weather
      event or natural disaster, act of God, strikes, work stoppages or other labor disturbances, riots or civil
      commotions, litigation, war or other act of any foreign nation, plague, epidemic, pandemic, power of government or
      governmental agency or authority, state of emergency, or any other cause like or unlike any cause mentioned above,
      whether natural or man-caused, which is beyond the control or authority of student housing. Island Condos does not
      discriminate in any of its programs, procedures, or practices against any person on the basis of age, citizenship,
      color, disability, national origin, political affiliation, race, religion, sex, sexual orientation, gender
      identity or status as a veteran.</p>

    <div class="page-break"></div>
      <ValidationObserver ref="formModalValidate">
      <div class="mb-5" v-if="studentType=='tenant'">
          <ValidationProvider name="profile_picture" rules="required" v-slot="{valid, errors}">
             <VueCropperComponents v-model="form.profile_picture" />
              <b-form-invalid-feedback v-if="errors[0]"
                                       v-html="errors[0]">
              </b-form-invalid-feedback>
          </ValidationProvider>
      </div>
      <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
        <div class="mr-3">
          <b-form-checkbox
              id="checkbox-1"
              v-model="form.status"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
          >
            I have read, understood, and accept
          </b-form-checkbox>
          <b-form-invalid-feedback v-if="errors[0]"
                                   v-html="errors[0]">

          </b-form-invalid-feedback>
        </div>
      </ValidationProvider>
      <div class="d-flex justify-content-center  align-center mt-3">
        <b-button variant="primary"
                  size="lg"
                  @click="sendForm"
        >
          {{ $t('save') }}
        </b-button>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import HousingReservationAgreementService from "@/services/HousingReservationAgreementService";
import {setHousingFormRoutePage} from "@/modules/housingAgreementForm/router/middleware"

export default {
    props:{
      studentType:{
          type:String,
      }
    },
  data() {
    return {
      form: {
        status: null,
          profile_picture:null

      }
    }
  },
    methods: {
    async sendForm() {
      const isValid = await this.$refs.formModalValidate.validate()
      if (isValid) {

          const data={
              'profile-picture':this.form.profile_picture,
          }

         HousingReservationAgreementService.sendStatus(this.$route.params.token,data)
            .then(() => {
              this.$toast.success(this.$t('transaction_successful'))
                setHousingFormRoutePage(true)
                this.$router.push('/housing/form/success')
            }).catch(err => {
          this.message = err.data.message
          this.showErrors(err)
        })

      }
    }
  }
}
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.5;
}

.centered {
  text-align: center;
}

.title {
  font-size: 26pt;
  text-align: center;
  font-weight: bold;
}

.subtitle {
  font-size: 12pt;
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.small-text {
  font-size: 11pt;
}

.list-item {
  margin-left: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 11pt;
}

table, td, th {
  border: 1px solid #BFBFBF;
}

th, td {
  padding: 8px;
  text-align: center;
}

.highlight {
  background-color: #F2F2F2;
}

a {
  color: #2F5496;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul, ol {
  margin-left: 40px;
}

p {
  margin-bottom: 1em;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1, h2, h3 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.signature-line {
  margin-top: 2em;
}

.indent {
  margin-left: 0.25in;
}

.double-indent {
  margin-left: 0.75in;
}

.bullet-list {
  margin-left: 0.75in;
}

.page-break {
  page-break-before: always;
}
</style>
<script setup>
import VueCropperComponents from "@/components/interactive-fields/VueCropperComponents.vue";
</script>
