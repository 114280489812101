import { render, staticRenderFns } from "./AgreementForm.vue?vue&type=template&id=58afb468&scoped=true&"
import script from "./AgreementForm.vue?vue&type=script&setup=true&lang=js&"
export * from "./AgreementForm.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./AgreementForm.vue?vue&type=style&index=0&id=58afb468&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58afb468",
  null
  
)

export default component.exports