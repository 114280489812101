<template>
  <div class="wrapper">
    <div class="message-box" v-if="message">
      <b-alert show variant="danger">
        <h4 class="alert-heading">unsuccessful!</h4>
        <p>{{$t('api.' + message)}}</p>
      </b-alert>
    </div>
    <CommonModal size="lg" ref="housingAgreementForm" :closeBtn="false" :noCloseOnBackDrop="true">
      <template v-slot:CommonModalTitle>
        <div>{{ $t("housing_agreement_form").toUpperCase() }}</div>
      </template>
      <template v-slot:CommonModalContent>

        <AgreementForm :studentType="type" v-if="formProcess === 'agreement-form'" />
      </template>
    </CommonModal>
  </div>
</template>
<script>

import HousingReservationAgreementService from "@/services/HousingReservationAgreementService";
import VueCropperComponents from "@/components/interactive-fields/VueCropperComponents.vue";
import AgreementForm from "@/modules/housingAgreementForm/pages/AgreementForm.vue";

export default {
  components: {
        AgreementForm,
        VueCropperComponents

  },
  metaInfo() {
    return {
      title: this.$t('housing_agreement_form')
    }
  },
  data() {
    return {
      message:null,
      formProcess: null,
      type: null,
    }
  },
  methods: {
     isReservation(){
       HousingReservationAgreementService.checkToken(this.$route.params.token)
           .then(res=>{
               this.type=res.data.data.type;
             if (res.data.success){
               this.openModal()
             }
           }).catch(err=>{
             this.message=err.data.message
             this.showErrors(err)
       })
     },
    openModal(){
      this.formProcess = 'agreement-form';
      this.$refs.housingAgreementForm.$refs.commonModal.show()
    }
  },
  created() {
    this.isReservation()
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  border: 1px solid #ddd;
  position: relative;
}


.message-box {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>


